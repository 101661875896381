import { Injectable } from '@angular/core';
import { MESSAGES } from '@constants/index.constant';
import { IPayloadInviteMember, IPermission, IPermissionUser, PagingDTO, RequestAPI } from '@dto/index.dto';
import { BenefitsApi } from '@remotes/apis/benefits.api';
import { MarketplaceApi } from '@remotes/apis/marketplace.api';
import { TokenService } from '@remotes/token/token.service';
import { AlertService } from '@services/lib/alert/alert.service';
import { HandlingService } from '@services/lib/handling/handling.service';
import { SpinnerService } from '@services/lib/spinner/spinner.service';
import { StringService } from '@services/lib/string/string.service';

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  constructor(
    private marketplaceApi: MarketplaceApi,
    private handlingService: HandlingService,
    private spinnerService: SpinnerService,
    private alertService: AlertService,
    private tokenService: TokenService,
    private stringService: StringService,
    private benefitsApi: BenefitsApi,
  ) {}

  async listMember(paging: PagingDTO, queryParams: any) {
    try {
      const request: RequestAPI = {
        model: 'BenefitSupportMember',
        func: 'listMember',
        queryParams: {
          ...paging,
          ...queryParams,
        },
      };
      const { data, ...item } = await this.marketplaceApi.get(request);
      if (data.length > 0) {
        data.forEach((member: any) => {
          member.fullName = member.fullName === null ? '-' : member.fullName;
          member.rolesMap =
            member.permissions.length > 0
              ? member.permissions
                  .filter((item: IPermission) => item.isAdmin || item.isReviewer)
                  .map((item: IPermission) => {
                    let as = '';
                    if (item.isAdmin) {
                      as = 'as Admin';
                    } else {
                      as = 'as Reviewer';
                    }
                    return `${this.stringService.normalize(item.permissionName)} ${as}`;
                  })
                  .join(', ')
                  .replace(/,\s*$/, '')
              : '-';
          member.coutriesMap =
            member.countries.length > 0
              ? member.countries
                  .map((item: any) => item.countryName)
                  .join(', ')
                  .replace(/,\s*$/, '')
              : '-';
          member.cta = [
            {
              label: '',
              iconUrl: 'assets/icons/icon-feather/icon-edit.svg',
              buttonType: 'editRow',
              variant: 'primary',
              customFunction: true,
            },
            {
              label: '',
              iconUrl: 'assets/icons/icon-feather/icon-trash.svg',
              buttonType: 'deactivate',
              fill: 'clear',
              color: 'danger',
              customFunction: true,
            },
          ];
        });
      }

      return {
        data,
        ...item,
      };
    } catch (e) {
      this.handlingService.errorResponse(e);
      return {};
    }
  }

  async inviteMember(params: IPayloadInviteMember) {
    try {
      if (params.countries.length === 0) {
        this.alertService.error('Please select country');
        return {};
      }
      await this.spinnerService.showSpinner();
      const request: RequestAPI = {
        model: 'BenefitSupportMember',
        func: 'inviteMember',
        params,
      };
      await this.marketplaceApi.post(request);
      await this.spinnerService.hideSpinner();
      await this.alertService.success(MESSAGES.SUCCESSFULLY_SAVED);
      return {
        data: 'success',
      };
    } catch (e) {
      await this.spinnerService.hideSpinner();
      this.handlingService.errorResponse(e);
      return {};
    }
  }

  async listRole() {
    try {
      const request: RequestAPI = {
        model: 'BenefitSupportMember',
        func: 'listRole',
      };
      return await this.marketplaceApi.get(request);
    } catch (e) {
      this.handlingService.errorResponse(e);
      return;
    }
  }

  async deactivateMember(email: string) {
    try {
      await this.tokenService.useTokenService();
      const { userData } = this.tokenService.token;

      if (email === userData.email) {
        this.alertService.error('Cannot deactivate your self!');
        return { isReload: false };
      }
      await this.spinnerService.showSpinner();
      const request: RequestAPI = {
        model: 'BenefitSupportMember',
        func: 'deactivateMember',
        params: {
          email,
        },
      };
      await this.marketplaceApi.post(request);
      await this.spinnerService.hideSpinner();
      return { isReload: true };
    } catch (e) {
      await this.spinnerService.hideSpinner();
      this.handlingService.errorResponse(e);
      return { isReload: false };
    }
  }

  async updateRolePermission(params: any) {
    try {
      await this.spinnerService.showSpinner();
      const request: RequestAPI = {
        model: 'BenefitSupportRole',
        func: 'updateRolePermission',
        params,
      };
      await this.marketplaceApi.post(request);
      await this.spinnerService.hideSpinner();
      this.alertService.success(MESSAGES.SUCCESSFULLY_UPDATED);
      return { data: true };
    } catch (e) {
      await this.spinnerService.hideSpinner();
      this.handlingService.errorResponse(e);
      return { data: false };
    }
  }

  async listRolePermission() {
    try {
      await this.spinnerService.showSpinner();
      const request: RequestAPI = {
        model: 'BenefitSupportRole',
        func: 'listRolePermission',
      };
      const { data } = await this.marketplaceApi.get(request);
      await this.spinnerService.hideSpinner();
      return data;
    } catch (e) {
      await this.spinnerService.hideSpinner();
      this.handlingService.errorResponse(e);
      return [];
    }
  }

  async listRolePermissionUser() {
    const spinnerId = Math.floor(1000 + Math.random() * 9000);

    try {
      await this.spinnerService.showSpinner(spinnerId);
      const request: RequestAPI = {
        model: 'Member',
        func: 'listRolePermissionUser',
      };
      const { data } = await this.benefitsApi.get(request);
      await this.spinnerService.hideSpinner(spinnerId);
      return data as IPermissionUser[];
    } catch (e) {
      await this.spinnerService.hideSpinner(spinnerId);
      await this.handlingService.errorResponse(e);
      return [];
    } finally {
      await this.spinnerService.hideSpinner(spinnerId);
    }
  }
}
